import {
  PHONE_VERIFICATION_URL,
  PHONE_CONFIRMATION_URL,
  SIGN_IN_URL,
  SIGN_UP_URL,
  GOOGLE_AUTH_URL,
  GOOGLE_AUTH_CALLBACK_URL,
} from 'src/constants/constants';
import { fetch } from 'src/utils/fetch';

const phoneSignIn = async (phone: string) => fetch.post(PHONE_VERIFICATION_URL, { phone });

const phoneSignInConfirm = async (phone: string, confirmCode: string, alias?: string) => {
  const response = await fetch.post(PHONE_CONFIRMATION_URL, { phone, confirmCode, alias });

  return response.data;
};

const emailSignIn = async (email: string, password: string, alias: string | undefined) => {
  const response = await fetch.post(SIGN_IN_URL, { email, password, alias });

  return response.data;
};

const emailSignUp = async (
  email: string,
  password: string,
  passwordConfirm: string,
  alias: string | undefined,
) => {
  const data: any = {
    email,
    password,
    passwordConfirm,
  };

  if (alias) {
    data.alias = alias;
  }

  const response = await fetch.post(SIGN_UP_URL, data);

  return response.data;
};

const googleSignIn = async () => {
  const response = await fetch.post(GOOGLE_AUTH_URL);

  return response.data;
};

const googleSignInCallback = async (code: string, alias: string | undefined) => {
  let uri = `${GOOGLE_AUTH_CALLBACK_URL}${code}`;

  if (alias) {
    uri += `&alias=${alias}`;
  }

  const response = await fetch.get(uri);

  return response.data;
};

export default {
  emailSignUp, emailSignIn, phoneSignIn, phoneSignInConfirm, googleSignIn, googleSignInCallback,
};
