import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useAppSelector } from 'src/hooks';
import { USER_STORAGE_KEY } from 'src/constants/constants';
import useLocalStorage from 'src/hooks/useLocalStorage';
import useActions from 'src/hooks/useActions';
import LinksList from 'src/components/LinksList';
import AddLinkForm from 'src/pages/Links/components/AddLinkForm';
import { TLink } from 'src/types/link';
import { LinkType } from 'src/constants/enums';

function LinksPage() {
  const { user } = useAppSelector((state) => state.user);
  const { links } = useAppSelector((state) => state.links);
  const [notTagLinks, setNotTagLinks] = useState<TLink[] | null>(null);
  const { getUser, getLinks } = useActions();
  const { getItem } = useLocalStorage();

  useEffect(() => {
    const userId = getItem(USER_STORAGE_KEY);

    if (userId) {
      getUser(userId.id);
      getLinks();
    }
  }, []);

  useEffect(() => {
    setNotTagLinks(links.filter((link: TLink) => link.type !== LinkType.TAG));
  }, [links]);

  return (
    <Container maxWidth="xs">
      {user?.id && <AddLinkForm userId={user.id} />}
      {notTagLinks && <LinksList links={notTagLinks} />}
    </Container>
  );
}

export default LinksPage;
