import React from 'react';
import Hero from 'src/pages/Main/components/Hero';
import Features from 'src/pages/Main/components/Features';

export function MainPage() {
  return (
    <>
      <Hero />
      <Features />
    </>
  );
}

export default MainPage;
