import { createSlice } from '@reduxjs/toolkit';
import { USER_STORAGE_KEY } from 'src/constants/constants';
import { AuthState } from 'src/types/auth';
import { AxiosError } from 'axios';
import {
  phoneSignIn,
  phoneSignInConfirm,
  emailSignIn,
  emailSignUp,
  googleSignIn,
  googleSignInCallback,
} from 'src/redux/auth/action-creators';

const getUserIdFromStorage = () => {
  try {
    return JSON.parse(localStorage.getItem(USER_STORAGE_KEY) as string);
  } catch (e) {
    return null;
  }
};

const initialState: AuthState = {
  user: getUserIdFromStorage(),
  isLoading: false,
  authError: null,
  isConfirmCodeRequired: null,
  googleSignInUrl: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: ((builder) => {
    builder
      .addCase(phoneSignIn.pending, () => {
      })
      .addCase(phoneSignIn.fulfilled, (state) => {
        state.isConfirmCodeRequired = true;
      })
      .addCase(phoneSignIn.rejected, (state, action) => {
        state.isConfirmCodeRequired = false;
        state.authError = action.payload as AxiosError;
      })
      .addCase(phoneSignInConfirm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(phoneSignInConfirm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isConfirmCodeRequired = false;
        state.user = action.payload;
      })
      .addCase(phoneSignInConfirm.rejected, (state, action) => {
        state.isLoading = false;
        state.authError = action.payload as AxiosError;
      })
      .addCase(emailSignIn.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(emailSignIn.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
      })
      .addCase(emailSignIn.rejected, (state, action) => {
        state.isLoading = false;
        state.authError = action.payload as AxiosError;
      })
      .addCase(emailSignUp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(emailSignUp.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
      })
      .addCase(emailSignUp.rejected, (state, action) => {
        state.isLoading = false;
        state.authError = action.payload as AxiosError;
      })
      .addCase(googleSignIn.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(googleSignIn.fulfilled, (state, action) => {
        state.googleSignInUrl = action.payload;
        state.isLoading = false;
      })
      .addCase(googleSignIn.rejected, (state, action) => {
        state.isLoading = false;
        state.authError = action.payload as AxiosError;
      })
      .addCase(googleSignInCallback.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(googleSignInCallback.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
      })
      .addCase(googleSignInCallback.rejected, (state, action) => {
        state.isLoading = false;
        state.authError = action.payload as AxiosError;
      });
  }),
});

export const authReducer = authSlice.reducer;
export default authSlice.actions;
