import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/api/notifications';

export const subscribePush = createAsyncThunk(
  '@user/subscribePush',
  async (subscription: any, thunkAPI) => {
    try {
      return await api.subscribePush(subscription);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const sendNotificationPush = createAsyncThunk(
  '@user/sendNotificationPush',
  async (notification: { message: string, alias: string | undefined }, thunkAPI) => {
    try {
      return await api.sendNotificationPush(notification);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
