import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/api/auth';

export const phoneSignIn = createAsyncThunk(
  '@auth/phoneSignIn',
  async (phone: string, thunkAPI) => {
    try {
      return await api.phoneSignIn(phone);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.data);
    }
  },
);

export const phoneSignInConfirm = createAsyncThunk(
  '@auth/phoneSignInConfirm',
  async ({
    phone,
    confirmCode,
    alias,
  }: {
    phone: string,
    confirmCode: string,
    alias?: string
  }, thunkAPI) => {
    try {
      return await api.phoneSignInConfirm(phone, confirmCode, alias);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.data);
    }
  },
);

export const emailSignIn = createAsyncThunk(
  '@auth/emailSignIn',
  async ({ email, password, alias }: {
    email: string,
    password: string,
    alias: string | undefined }, thunkAPI) => {
    try {
      return await api.emailSignIn(email, password, alias);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.data);
    }
  },
);

export const emailSignUp = createAsyncThunk(
  '@auth/emailSignUp',
  async ({
    email, password, passwordConfirm, alias,
  }: {
    email: string,
    password: string,
    passwordConfirm: string,
    alias: string | undefined
  }, thunkAPI) => {
    try {
      return await api.emailSignUp(email, password, passwordConfirm, alias);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.data);
    }
  },
);

export const googleSignIn = createAsyncThunk(
  '@auth/googleSignIn',
  async (_, thunkAPI) => {
    try {
      return await api.googleSignIn();
    } catch (error) {
      return thunkAPI.rejectWithValue(error.data);
    }
  },
);

export const googleSignInCallback = createAsyncThunk(
  '@auth/googleSignInCallback',
  async ({ code, alias }: { code: string,
    alias: string | undefined }, thunkAPI) => {
    try {
      return await api.googleSignInCallback(code, alias);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.data);
    }
  },
);
