import React, { useState } from 'react';
import {
  Button, FormControl, TextField, Alert,
} from '@mui/material';
import useActions from 'src/hooks/useActions';
import { useTranslation } from 'react-i18next';
import { LinkType } from 'src/constants/enums';

interface RedirectLinkPFormProps {
  userId: string
}

const INITIAL_STATE = {
  url: '',
  title: '',
};

function RedirectLinkForm({ userId }: RedirectLinkPFormProps) {
  const [linkData, setLinkData] = useState(INITIAL_STATE);
  const { createLink } = useActions();
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkData({ ...linkData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const { url, title } = linkData;

    createLink({
      owner: userId,
      type: LinkType.REDIRECT,
      url,
      active: false,
      title,
    });

    setLinkData({ url: '', title: '' });
  };

  return (
    <form onSubmit={handleSubmit} className="grid gap-4">
      <Alert severity="info">{t('All visitors will be automatically redirected to the specified link.')}</Alert>
      <FormControl>
        <TextField
          label="URL"
          autoComplete="url"
          name="url"
          value={linkData.url}
          onChange={handleChange}
          required
        />
      </FormControl>
      <FormControl>
        <TextField
          label={t('Description')}
          name="title"
          value={linkData.title}
          onChange={handleChange}
          fullWidth
          required
        />
      </FormControl>
      <Button size="large" type="submit" variant="contained" color="primary">
        {t('Create a link')}
      </Button>
    </form>
  );
}

export default RedirectLinkForm;
