import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import logo from 'src/assets/logo_white.svg';

interface QrCodeViewProps {
  url: string;
  size?: number;
  className?: string;
}

function QrCodeView({ url, size, className }: QrCodeViewProps) {
  const mm = 3.7795275591;
  const aspectRatioLogo = 2.75;
  const logoSize = 22 * mm;
  const width = 46 * mm;
  const bgColor = '#000';
  const qrColor = 'rgb(52,52,52)';

  return (
    <div
      className={`${className} bg-black text-center rounded-md`}
      style={{ border: `${mm}px solid red`, padding: `${2 * mm}px` }}
    >
      <div style={{ width: `${width}px` }} className="flex flex-col text-white">
        <span className="flex items-center justify-center text-sm font-bold flex-1 text-center font-sans leading-none" style={{ color: qrColor, paddingBottom: `${2 * mm}px` }}>тут звʼязок з власником</span>
        <QRCodeSVG
          value={url}
          size={size}
          level="H"
          fgColor={qrColor}
          bgColor={bgColor}
          imageSettings={{
            src: logo,
            height: logoSize / aspectRatioLogo,
            width: logoSize,
            excavate: true,
          }}
        />
      </div>
    </div>
  );
}

QrCodeView.defaultProps = {
  size: 46 * 3.7795275591,
  className: '',
};

export default QrCodeView;
