import {
  NOTIFICATIONS_PUSH_SUBSCRIBE_URL,
  NOTIFICATIONS_PUSH_SEND_URL,
} from 'src/constants/constants';
import { fetch } from 'src/utils/fetch';

const subscribePush = async (subscription: any) => {
  const response = await fetch.post(NOTIFICATIONS_PUSH_SUBSCRIBE_URL, subscription);

  return response.data;
};

const sendNotificationPush = async (message: any) => {
  const response = await fetch.post(NOTIFICATIONS_PUSH_SEND_URL, message);

  return response.data;
};

export default { subscribePush, sendNotificationPush };
