import React, { useEffect } from 'react';
import useActions from 'src/hooks/useActions';
import { useAppSelector } from 'src/hooks';
import { Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as GoogleIcon } from 'src/assets/icons/google.svg';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';

function GoogleSignIn() {
  const [searchParams] = useSearchParams();
  const { googleSignInCallback, googleSignIn } = useActions();
  const { googleSignInUrl } = useAppSelector((state) => state.auth);
  const { getItem, removeItem } = useLocalStorage();
  const { t } = useTranslation();

  useEffect(() => {
    const code = searchParams.get('code');

    if (code) {
      const alias = getItem('assign-alias');

      googleSignInCallback({ code, alias });

      if (alias) {
        removeItem('assign-alias');
      }
    } else {
      googleSignIn();
    }
  }, []);

  if (googleSignInUrl) {
    return <Button variant="outlined" fullWidth startIcon={<GoogleIcon />} component="a" href={googleSignInUrl}>{t('Continue with %t').replace('%t', 'Google')}</Button>;
  }

  return null;
}

export default GoogleSignIn;
