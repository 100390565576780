import 'src/App.css';
import { Outlet, useLocation } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import {
  Backdrop, Box, CircularProgress, CssBaseline, ThemeOptions, ThemeProvider,
  createTheme,
} from '@mui/material';
import themeSettings from 'src/theme';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga4';
import DrawerAppBar from 'src/components/DrawerAppBar';
import { DrawerHeader } from 'src/components/Drawer';
import { useAppSelector } from 'src/hooks';
import 'src/utils/i18n';

if (process.env.REACT_APP_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
}

function App() {
  const theme = useMemo(() => createTheme(themeSettings() as ThemeOptions), []);

  const isUserLoading = useAppSelector((state) => state.user.isLoading);
  const isTagLoading = useAppSelector((state) => state.links.isLoading);
  const isAuthLoading = useAppSelector((state) => state.auth.isLoading);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (process.env.REACT_APP_TRACKING_ID) {
      ReactGA.send({ hitType: 'pageview', path: window.location.pathname + window.location.search });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
        <CssBaseline />
        {(isUserLoading || isTagLoading || isAuthLoading) && <Backdrop sx={{ color: '#fff', zIndex: (themeOptions) => themeOptions.zIndex.drawer + 1 }} open><CircularProgress color="inherit" /></Backdrop>}
        <Box className="flex min-h-screen">
          <DrawerAppBar />
          <Box component="main" className="flex-grow mt-8">
            <DrawerHeader />
            <Box className="grid gap-16 pb-16">
              <Outlet />
            </Box>
          </Box>
        </Box>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;
