import React, { useEffect, useState } from 'react';
import LinksList from 'src/components/LinksList';
import UserForm from 'src/pages/Account/components/UserForm';
import toast from 'react-hot-toast';
import useActions from 'src/hooks/useActions';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { Container } from '@mui/material';
import { USER_STORAGE_KEY } from 'src/constants/constants';
import { useAppSelector } from 'src/hooks';
import { TLink } from 'src/types/link';
import { LinkType } from 'src/constants/enums';

function AccountPage() {
  const { user, userError } = useAppSelector((state) => state.user);
  const { links } = useAppSelector((state) => state.links);
  const [tagLinks, setTagLinks] = useState<TLink[] | null>(null);
  const { getUser, getLinks } = useActions();
  const { getItem } = useLocalStorage();

  useEffect(() => {
    const currentUser = getItem(USER_STORAGE_KEY);

    if (currentUser?.id) {
      getUser(currentUser?.id);
      getLinks();
    }
  }, []);

  useEffect(() => {
    if (userError) {
      toast.error(userError.message || userError.error || userError.data || userError);
    }
  }, [userError]);

  useEffect(() => {
    setTagLinks(links.filter((link: TLink) => link.type === LinkType.TAG));
  }, [links]);

  return (
    <Container disableGutters>
      {user && <UserForm user={user} />}
      {tagLinks && <LinksList links={tagLinks} />}
    </Container>
  );
}

export default AccountPage;
