// User-related URLs
export const USERS_BASE_URL = '/users/';
export const AUTH_BASE_URL = '/auth/';
export const SIGN_IN_URL = `${AUTH_BASE_URL}sign-in/`;
export const SIGN_UP_URL = `${AUTH_BASE_URL}sign-up/`;
export const PHONE_VERIFICATION_URL = `${AUTH_BASE_URL}phone/`;
export const PHONE_CONFIRMATION_URL = `${AUTH_BASE_URL}phone-confirm/`;
export const GOOGLE_AUTH_URL = `${AUTH_BASE_URL}google/`;
export const GOOGLE_AUTH_CALLBACK_URL = `${AUTH_BASE_URL}google-callback?code=`;
export const USER_STORAGE_KEY = 'USER';

// Links and Subscriptions
export const LINKS_BASE_URL = '/links/';

// Notifications
export const NOTIFICATIONS_BASE_URL = '/notifications/';
export const NOTIFICATIONS_PUSH_SUBSCRIBE_URL = `${NOTIFICATIONS_BASE_URL}subscribe`;
export const NOTIFICATIONS_PUSH_SEND_URL = `${NOTIFICATIONS_BASE_URL}send`;
