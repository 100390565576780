import axios, { RawAxiosRequestHeaders } from 'axios';
import { USER_STORAGE_KEY } from 'src/constants/constants';
import useLocalStorage from 'src/hooks/useLocalStorage';

const baseConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 10000,
  withCredentials: true,
};

export const fetch = axios.create(baseConfig);

fetch.interceptors.request.use((config) => {
  const updatedConfig = { ...config };
  const { getItem } = useLocalStorage();
  const token = getItem('token');

  if (token) {
    updatedConfig.headers = config.headers ?? {};
    (updatedConfig.headers as RawAxiosRequestHeaders).Authorization = `Bearer ${token}`;
  }

  return updatedConfig;
});

fetch.interceptors.response.use((response) => response, (error) => {
  const { status } = error.response;
  const { removeItem } = useLocalStorage();

  if (status === 401) {
    removeItem(USER_STORAGE_KEY);
    window.location.reload();

    return;
  }

  throw error.response;
});

export const fetchFile = axios.create(baseConfig);

fetchFile.interceptors.request.use((config) => {
  const updatedConfig = { ...config };
  const { getItem } = useLocalStorage();
  const token = getItem('token');

  updatedConfig.headers = config.headers ?? {};
  (updatedConfig.headers as RawAxiosRequestHeaders)['Content-Type'] = 'multipart/form-data';

  if (token) {
    (updatedConfig.headers as RawAxiosRequestHeaders).Authorization = `Bearer ${token}`;
  }

  return config;
});
