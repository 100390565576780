import React from 'react';
import {
  Box,
  Button,
  SvgIcon,
  Typography,
} from '@mui/material';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { SocialLink, UserBio } from 'src/types/user';
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';
import Avatar from '@mui/material/Avatar';
import { useTranslation } from 'react-i18next';
import { formatUserPicture } from 'src/utils';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as TelegramIcon } from 'src/assets/icons/telegram.svg';
import { ReactComponent as WhatsAppIcon } from 'src/assets/icons/whatsapp.svg';
import { ReactComponent as ViberIcon } from 'src/assets/icons/viber.svg';
import { ReactComponent as InstagramIcon } from 'src/assets/icons/instagram.svg';
import { ReactComponent as TikTokIcon } from 'src/assets/icons/tiktok.svg';
import { ReactComponent as FacebookIcon } from 'src/assets/icons/facebook.svg';
import { ReactComponent as YouTubeIcon } from 'src/assets/icons/youtube.svg';
import { ReactComponent as LocationIcon } from 'src/assets/icons/location.svg';
import { ReactComponent as SpotifyIcon } from 'src/assets/icons/spotify.svg';

interface UserCardProps {
  userBio: UserBio
}

function UserCard({ userBio }: UserCardProps) {
  const urlRegExp = /^(https:\/\/|http:\/\/)/;
  const { t } = useTranslation();
  const {
    cardNumber,
    profilePicture,
    description,
    name,
    phone,
    link,
    socialLinks,
  } = userBio;

  const imageUrl = formatUserPicture(profilePicture?.data.data, profilePicture?.contentType);
  const formatCardNumber = (cardNumberText: string) => {
    const formattedCardNumber = cardNumberText.replace(/\D/g, '');
    const groups = formattedCardNumber.match(/.{1,4}/g);

    if (groups) {
      return groups.join(' ');
    }

    return formattedCardNumber;
  };

  const handleClickCardNumber = (text: string) => {
    copy(text);
    toast.success(t('Copied: %t').replace('%t', formatCardNumber(text)));
  };

  const renderSocialLink = (socialLink: SocialLink) => {
    const { type, url } = socialLink;

    switch (type) {
      case 'spotify':
        return (
          <IconButton component="a" key={type + url} target="_blank" rel="noreferrer" href={url}>
            <SvgIcon fontSize="large"><SpotifyIcon /></SvgIcon>
          </IconButton>
        );
      case 'telegram':
        return (
          <IconButton
            component="a"
            key={type + url}
            target="_blank"
            rel="noreferrer"
            href={`https://t.me/${url.replace('@', '')}`}
          >
            <SvgIcon fontSize="large"><TelegramIcon /></SvgIcon>
          </IconButton>
        );
      case 'viber':
        return (
          <IconButton component="a" key={type + url} href={url.replace('+', '')}>
            <SvgIcon fontSize="large"><ViberIcon /></SvgIcon>
          </IconButton>
        );
      case 'instagram':
        return (
          <IconButton component="a" key={type + url} target="_blank" rel="noreferrer" href={url}>
            <SvgIcon fontSize="large"><InstagramIcon /></SvgIcon>
          </IconButton>
        );
      case 'whatsapp':
        return (
          <IconButton component="a" key={type + url} target="_blank" rel="noreferrer" href={url}>
            <SvgIcon fontSize="large"><WhatsAppIcon /></SvgIcon>
          </IconButton>
        );
      case 'tiktok':
        return (
          <IconButton component="a" key={type + url} target="_blank" rel="noreferrer" href={url}>
            <SvgIcon fontSize="large"><TikTokIcon /></SvgIcon>
          </IconButton>
        );
      case 'facebook':
        return (
          <IconButton component="a" key={type + url} target="_blank" rel="noreferrer" href={url}>
            <SvgIcon fontSize="large"><FacebookIcon /></SvgIcon>
          </IconButton>
        );
      case 'youtube':
        return (
          <IconButton component="a" key={type + url} target="_blank" rel="noreferrer" href={url}>
            <SvgIcon fontSize="large"><YouTubeIcon /></SvgIcon>
          </IconButton>
        );
      case 'place':
        return (
          <IconButton component="a" key={type + url} target="_blank" rel="noreferrer" href={url}>
            <SvgIcon fontSize="large"><LocationIcon /></SvgIcon>
          </IconButton>
        );
      default:
        return null;
    }
  };

  return (
    <Box className="bg-gray-50 grid gap-2 items-center p-4 rounded-xl">
      <Box className="grid gap-4">
        {profilePicture?.data && (
        <Box className="flex justify-center">
          <Avatar
            imgProps={{ crossOrigin: 'anonymous' }}
            sx={{ width: 120, height: 120 }}
            src={imageUrl}
          />
        </Box>
        )}
        <Box>
          {name && (
            <Typography component="div" variant="h5">
              {name}
            </Typography>
          )}
          {description && (
            <Typography variant="subtitle1" component="div">
              {description}
            </Typography>
          )}
        </Box>
      </Box>

      <Box className="grid gap-2">
        {phone && (
        <Box>
          <Button
            component="a"
            href={`tel:${phone}`}
            fullWidth
            size="large"
            variant="contained"
            startIcon={<CallOutlinedIcon />}
          >
            {phone}
          </Button>
        </Box>

        )}
        {cardNumber && (
        <Box>
          <Button
            fullWidth
            variant="contained"
            size="large"
            startIcon={<CreditCardOutlinedIcon />}
            endIcon={<ContentCopyOutlinedIcon />}
            onClick={() => {
              handleClickCardNumber(cardNumber);
            }}
          >
            {formatCardNumber(cardNumber)}
          </Button>
        </Box>
        )}
        {(link && link.value) && (
        <Box>
          <Button
            component="a"
            fullWidth
            variant="contained"
            target="_blank"
            size="large"
            rel="noreferrer"
            href={`https://${link.value.replace(urlRegExp, '')}`}
            endIcon={<OpenInNewOutlinedIcon />}
          >
            <span
              className="break-all"
            >
              {link.label || link.value.replace(/^(https:\/\/|http:\/\/)/, '')}
            </span>
          </Button>
        </Box>
        )}

        <Box className="flex flex-wrap justify-center">
          {socialLinks.map((socialLink: SocialLink) => (
            <Box className="inline-block text-center" key={`_key${socialLink.id}`}>
              {renderSocialLink(socialLink)}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default UserCard;
