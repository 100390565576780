import React, { useState } from 'react';
import {
  Alert,
  Button,
  FormControl,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useActions from 'src/hooks/useActions';
import { LinkType } from 'src/constants/enums';

interface ApplicationLinkFormProps {
  userId: string
}

const INITIAL_STATE = {
  appleUrl: '',
  googleUrl: '',
  huaweiUrl: '',
  title: '',
};

function ApplicationLinkForm({ userId }: ApplicationLinkFormProps) {
  const [linkData, setLinkData] = useState(INITIAL_STATE);
  const { createLink } = useActions();
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkData({ ...linkData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const {
      appleUrl,
      googleUrl,
      huaweiUrl,
      title,
    } = linkData;

    createLink({
      owner: userId,
      type: LinkType.APPLICATION,
      appleUrl,
      googleUrl,
      huaweiUrl,
      active: false,
      title,
    });

    setLinkData(INITIAL_STATE);
  };

  return (
    <form onSubmit={handleSubmit} className="grid gap-4">
      <Alert severity="info">
        {t('All visitors will be automatically redirected to the specified link depending on the device.')}
      </Alert>
      <FormControl>
        <TextField
          label="iOS"
          autoComplete="url"
          name="appleUrl"
          value={linkData.appleUrl}
          onChange={handleChange}
          required
        />
      </FormControl>
      <FormControl>
        <TextField
          label="Android"
          autoComplete="url"
          name="googleUrl"
          value={linkData.googleUrl}
          onChange={handleChange}
          required
        />
      </FormControl>
      <FormControl>
        <TextField
          label="HUAWEI"
          autoComplete="url"
          name="huaweiUrl"
          value={linkData.huaweiUrl}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl>
        <TextField
          label="Опис"
          name="title"
          value={linkData.title}
          onChange={handleChange}
          fullWidth
          required
        />
      </FormControl>
      <Button type="submit" variant="contained" color="primary" size="large">
        {t('Create a link')}
      </Button>
    </form>
  );
}

export default ApplicationLinkForm;
