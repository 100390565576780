import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { notificationsReducer } from 'src/redux/notifications/notifications.slice';
import { userReducer } from 'src/redux/user/user.slice';
import { linksReducer } from 'src/redux/links/links.slice';
import { authReducer } from 'src/redux/auth/auth.slice';

export const store = configureStore({
  reducer: combineReducers({
    user: userReducer,
    auth: authReducer,
    notifications: notificationsReducer,
    links: linksReducer,
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
