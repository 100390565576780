import { createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { UserState } from 'src/types/user';
import {
  deletePicture,
  getUser,
  update,
  updatePicture,
} from 'src/redux/user/action-creators';

const initialState: UserState = {
  user: null,
  isLoading: false,
  userError: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: ((builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.userError = null;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        state.user = null;
        state.userError = action.payload as AxiosError;
      })
      .addCase(update.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.userError = null;
      })
      .addCase(update.rejected, (state, action) => {
        state.isLoading = false;
        state.userError = action.payload as AxiosError;
      })
      .addCase(updatePicture.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePicture.fulfilled, (state) => {
        state.isLoading = false;
        state.userError = null;
      })
      .addCase(updatePicture.rejected, (state, action) => {
        state.isLoading = false;
        state.userError = action.payload;
      })
      .addCase(deletePicture.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePicture.fulfilled, (state) => {
        state.isLoading = false;
        state.userError = null;
      })
      .addCase(deletePicture.rejected, (state, action) => {
        state.isLoading = false;
        state.userError = action.payload as AxiosError;
      });
  }),
});

export const userReducer = userSlice.reducer;
export default userSlice.actions;
