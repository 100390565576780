const useLocalStorage = () => {
  const setItem = (key: string, value: any) => {
    if (!value) return;

    localStorage.setItem(key, JSON.stringify(value));
  };

  const getItem = (key: string) => {
    const userString = localStorage.getItem(key);

    if (userString) {
      try {
        return JSON.parse(userString);
      } catch (e) {
        return null;
      }
    }
    return null;
  };

  const removeItem = (key: string) => {
    localStorage.removeItem(key);
  };

  return { setItem, getItem, removeItem };
};

export default useLocalStorage;
