import { createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { NotificationsState } from 'src/types/notifications';
import {
  subscribePush,
  sendNotificationPush,
} from 'src/redux/notifications/action-creators';

const initialState: NotificationsState = {
  isLoading: false,
  notificationsError: null,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: ((builder) => {
    builder
      .addCase(subscribePush.pending, () => {})
      .addCase(subscribePush.fulfilled, (state) => {
        state.notificationsError = null;
      })
      .addCase(subscribePush.rejected, (state, action) => {
        state.notificationsError = action.payload as AxiosError;
      })
      .addCase(sendNotificationPush.pending, () => {})
      .addCase(sendNotificationPush.fulfilled, (state) => {
        state.notificationsError = null;
      })
      .addCase(sendNotificationPush.rejected, (state, action) => {
        state.notificationsError = action.payload as AxiosError;
      });
  }),
});

export const notificationsReducer = notificationsSlice.reducer;
export default notificationsSlice.actions;
