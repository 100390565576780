import {
  Container, Paper, SvgIcon, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

function Features() {
  const { t } = useTranslation();

  return (
    <Container id="features">
      <div className="grid gap-8 flex-wrap justify-center lg:grid-cols-3">
        <Paper className="!bg-red-50 grid gap-2 p-8" sx={{ borderRadius: '2.5rem' }}>
          <div className="flex items-center justify-center w-12 h-12 text-white bg-black rounded-full">
            <SvgIcon color="secondary">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" /></svg>
            </SvgIcon>
          </div>
          <Typography variant="h2" fontFamily="Black Street, arial">
            {t('Anonymous messages')}
          </Typography>
          <Typography>
            {t('Receive anonymous push notifications without installing an app')}
          </Typography>
        </Paper>
        <Paper className="!bg-black grid gap-2 p-8" sx={{ borderRadius: '2.5rem' }}>
          <div className="flex items-center justify-center w-12 h-12 text-black bg-red-50 rounded-full">
            <SvgIcon>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" /></svg>
            </SvgIcon>
          </div>
          <Typography variant="h2" color="secondary" fontFamily="Black Street, arial">
            {t('Flexible links')}
          </Typography>
          <Typography color="secondary">
            {t('Automatic link selection based on the user\'s platform')}
          </Typography>
        </Paper>
        <Paper className="!bg-red-50 grid gap-2 p-8" sx={{ borderRadius: '2.5rem' }}>
          <div className="flex items-center justify-center w-12 h-12 text-white bg-black rounded-full">
            <SvgIcon color="secondary">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" /></svg>
            </SvgIcon>
          </div>
          <Typography variant="h2" fontFamily="Black Street, arial">
            {t('Analytics collection')}
          </Typography>
          <Typography>
            {t('Analytics include the number of scans, time, location, platform, and a source of a transition')}
          </Typography>
        </Paper>
      </div>
    </Container>
  );
}

export default Features;
