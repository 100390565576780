import { LINKS_BASE_URL } from 'src/constants/constants';
import { fetch } from 'src/utils/fetch';
import { Scan, TLink } from 'src/types/link';

const createLinks = async (links: Partial<TLink>[]) => {
  const response = await fetch.post(`${LINKS_BASE_URL}many`, links);

  return response.data;
};

const createLink = async (link: Partial<TLink>) => {
  const response = await fetch.post(LINKS_BASE_URL, link);

  return response.data;
};

const deleteLink = async (id: string) => {
  const response = await fetch.delete(`${LINKS_BASE_URL}${id}`);

  return response.data;
};

const updateLink = async (id: string, data: Partial<TLink>) => {
  const response = await fetch.patch<TLink>(`${LINKS_BASE_URL}${id}`, data);

  return response.data;
};

const assignLink = async (alias: string, userId: string) => {
  const response = await fetch.patch(`${LINKS_BASE_URL}users/${alias}/${userId}`);

  return response.data;
};

const getLinks = async () => {
  const response = await fetch.get(`${LINKS_BASE_URL}`);

  return response.data;
};

const getLinkByAlias = async (alias: string) => {
  const response = await fetch.get<TLink>(`${LINKS_BASE_URL}${alias}`);

  return response.data;
};

const updateLinkScansByAlias = async (alias: string, data: Partial<Scan>) => {
  const response = await fetch.patch<TLink>(`${LINKS_BASE_URL}scans/${alias}`, data);

  return response.data;
};

export default {
  updateLinkScansByAlias,
  getLinkByAlias,
  getLinks,
  assignLink,
  updateLink,
  deleteLink,
  createLinks,
  createLink,
};
