import React, { useCallback, useEffect, useState } from 'react';
import { Link as RLink, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Box,
  Container, Divider,
  Link,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { USER_STORAGE_KEY } from 'src/constants/constants';
import { useAppSelector } from 'src/hooks';
import GoogleSignIn from 'src/pages/Auth/components/GoogleSignIn';
import FormPhone from 'src/pages/Auth/components/FormPhone';
import FormSignIn from 'src/pages/Auth/components/FormSignIn';
import FormSignUp from 'src/pages/Auth/components/FormSignUp';
import logo from 'src/assets/logo.svg';
import useActions from 'src/hooks/useActions';

function AuthPage() {
  const navigate = useNavigate();
  const { alias } = useParams();
  const { setItem } = useLocalStorage();
  const { authError, user } = useAppSelector((state) => state.auth);
  const [usePhone, setUsePhone] = useState(false);
  const [useLogin, setUseLogin] = useState(true);
  const { t } = useTranslation();
  const { emailSignIn, emailSignUp } = useActions();

  useEffect(() => {
    if (user) {
      setItem(USER_STORAGE_KEY, user);
      setItem('token', user.token);
      navigate('/account');
    }
  }, [user]);

  useEffect(() => {
    if (authError) {
      toast.error(authError.message || authError.error);
    }
  }, [authError]);

  useEffect(() => {
    if (alias) {
      setItem('assign-alias', alias);
    }
  }, []);

  const handleUsePhone = () => {
    setUsePhone(!usePhone);
  };

  const handleUseLogin = () => {
    setUseLogin(!useLogin);
  };

  const handleSignInSubmit = useCallback(({ email, password } :{
    email: string,
    password: string,
  }) => emailSignIn({ email, password, alias }), [emailSignIn, alias]);

  const handleSignUpSubmit = useCallback(({ email, password, passwordConfirm } :{
    email: string,
    password: string,
    passwordConfirm: string,
  }) => emailSignUp({
    email, password, passwordConfirm, alias,
  }), [emailSignUp, alias]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        className="gap-8 rounded-xl bg-white"
        sx={{
          marginTop: 8, padding: 4, display: 'flex', flexDirection: 'column',
        }}
      >
        <RLink to="/">
          <img className="h-12" src={logo} alt="Cattlet" />
        </RLink>

        <Box component="header">
          <Typography component="h1" variant="h5" fontWeight="bold">
            {alias ? t('Activation') : t('Auth')}
          </Typography>
          <Typography component="p">
            {alias ? t('Transfer ownership, attach the QR code to the new owner')
              : t('To log in to the account')}
          </Typography>
        </Box>

        <Box className="grid gap-8">
          <Box className="grid gap-2">
            <GoogleSignIn />
          </Box>

          <Divider><Typography fontWeight="bold">{t('or')}</Typography></Divider>

          <Box className="grid gap-2">
            <Typography fontSize="small" className="text-right -mb-2">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link component="button" underline="hover" onClick={handleUsePhone}>
                {t('Use %t').replace('%t', usePhone ? 'Email' : t('Phone'))}
              </Link>
            </Typography>

            {!usePhone ? (
              <>
                {useLogin
                  ? <FormSignIn onSubmit={handleSignInSubmit} />
                  : <FormSignUp onSubmit={handleSignUpSubmit} />}
                <Typography fontSize="small">
                  {t('Already registered?')}
                  {' '}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link component="button" underline="hover" onClick={handleUseLogin}>
                    {useLogin ? t('Sign Up') : t('Sign In')}
                  </Link>
                </Typography>
              </>
            ) : <FormPhone />}
          </Box>
        </Box>
      </Box>
      <Typography fontSize="small" sx={{ marginTop: 1 }}><RLink to="/privacy-policy" target="_blank" className="underline">{t('Agree to the privacy policy')}</RLink></Typography>
    </Container>
  );
}

export default AuthPage;
