import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from '../i18n/en/translation.json';
import translationUA from '../i18n/ua/translation.json';

export default i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      ua: {
        translation: translationUA,
      },
    },
    fallbackLng: 'ua',
    interpolation: {
      escapeValue: false,
    },
  });
