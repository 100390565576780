import React from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import QrCodeView from 'src/components/QrCodeView';
import { TLink } from 'src/types/link';
import useActions from 'src/hooks/useActions';
import { LinkType } from 'src/constants/enums';

function QRPrintPage() {
  const { count } = useParams();
  const draftLinks: Partial<TLink>[] = [];
  const { createLinks } = useActions();

  const renderQRs = (quantity: number) => {
    const mm = 3.7795275591;
    const qrCodes = [];

    for (let i = 0; i < quantity; i += 1) {
      const alias = uuidv4();

      draftLinks.push({
        alias,
        title: 'QR code sticker',
        type: LinkType.TAG,
        url: '',
        active: false,
      });

      qrCodes.push(
        <div className="inline-flex gap-3 p-2 bg-black" key={i} style={{ border: `${mm}px solid red`, padding: `${2 * mm}px` }}>
          <QrCodeView url={`https://cattlet.com/${alias}`} />
        </div>,
      );
    }

    return qrCodes;
  };

  const handleClick = () => {
    createLinks(draftLinks);
  };

  return (
    <>
      <button type="button" className="print:hidden px-2 py-0.5" onClick={handleClick}>Create Tags</button>
      <section className="flex flex-wrap items-start flex-col p-2">
        {renderQRs(Number(count))}
      </section>
    </>
  );
}

export default QRPrintPage;
