import { bindActionCreators } from '@reduxjs/toolkit';
import * as UserActionCreators from 'src/redux/user/action-creators';
import * as LinkActionCreators from 'src/redux/links/action-creators';
import * as AuthActionCreators from 'src/redux/auth/action-creators';
import * as NotificationsActionCreators from 'src/redux/notifications/action-creators';
import { useAppDispatch } from 'src/hooks/index';

const useActions = () => {
  const dispatch = useAppDispatch();

  return bindActionCreators({
    ...UserActionCreators,
    ...LinkActionCreators,
    ...AuthActionCreators,
    ...NotificationsActionCreators,
  }, dispatch);
};

export default useActions;
