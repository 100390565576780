import MuiPhone from 'src/components/MuiPhone';
import React, { useEffect, useState } from 'react';
import useActions from 'src/hooks/useActions';
import { Button, TextField } from '@mui/material';
import { useAppSelector } from 'src/hooks';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { validatePhone } from 'src/hooks/usePhone';

function FormPhone() {
  const { alias } = useParams();
  const { isConfirmCodeRequired } = useAppSelector((state) => state.auth);
  const [phone, setPhone] = useState('');
  const [confirmCode, setConfirmCode] = useState('');
  const { phoneSignIn, phoneSignInConfirm } = useActions();
  const { t } = useTranslation();

  const onSubmitPhone = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isConfirmCodeRequired && validatePhone(phone)) {
      phoneSignIn(phone);
    }
  };

  useEffect(() => {
    if (confirmCode.length === 4) {
      phoneSignInConfirm({ phone, confirmCode, alias });
    }
  }, [confirmCode]);

  useEffect(() => {
    if ('OTPCredential' in window) {
      const ac = new AbortController();

      navigator.credentials.get({
        otp: { transport: ['sms'] },
        signal: ac.signal,
      } as any).then((otp: any) => {
        if (otp?.code) {
          setConfirmCode(otp?.code);
        }
      }).catch(() => {});

      return () => {
        ac.abort('exit');
      };
    }

    return () => {};
  }, []);

  return (
    <form className="grid gap-2" onSubmit={onSubmitPhone} noValidate>
      <MuiPhone
        value={phone}
        id="phone"
        name="phone"
        required
        autoFocus
        onChange={(value: string) => setPhone(value)}
      />

      {isConfirmCodeRequired
                && (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={confirmCode}
                  autoFocus
                  name="confirmCode"
                  type="text"
                  inputMode="numeric"
                  autoComplete="one-time-code"
                  label={t('Confirm code')}
                  inputProps={{ pattern: '/{4}/v' }}
                  onChange={(e: any) => setConfirmCode(e.target.value)}
                  required
                />
                )}
      {!isConfirmCodeRequired && (
      <Button type="submit" fullWidth variant="contained">
        {t('Send code')}
      </Button>
      )}
    </form>
  );
}

export default FormPhone;
