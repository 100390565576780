import { TLink, LinkState } from 'src/types/link';
import { createSlice, Draft } from '@reduxjs/toolkit';
import {
  assignLink,
  updateLink,
  createLink,
  deleteLink,
  getLinkByAlias,
  getLinks, createLinks,
} from 'src/redux/links/action-creators';
import { AxiosError } from 'axios';

const initialState: LinkState = {
  links: [],
  link: null,
  isLoading: false,
  linkError: null,
};

const linksSlice = createSlice({
  name: 'links',
  initialState,
  reducers: {},
  extraReducers: ((builder) => {
    builder
      .addCase(getLinks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLinks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.links = action.payload;
      })
      .addCase(getLinks.rejected, (state, action) => {
        state.isLoading = false;
        state.linkError = action.payload as AxiosError;
      })
      .addCase(deleteLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteLink.fulfilled, (state, action) => {
        state.isLoading = false;
        state.links = action.payload;
      })
      .addCase(deleteLink.rejected, (state, action) => {
        state.isLoading = false;
        state.linkError = action.payload as AxiosError;
      })
      .addCase(updateLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateLink.fulfilled, (state, action) => {
        state.isLoading = false;
        state.links = (state.links as Draft<TLink>[]).map((link: TLink) => {
          if (link.id === action.payload.id) {
            return { ...link, ...action.payload };
          }
          return link;
        });
      })
      .addCase(updateLink.rejected, (state, action) => {
        state.isLoading = false;
        state.linkError = action.payload as AxiosError;
      })
      .addCase(assignLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assignLink.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(assignLink.rejected, (state, action) => {
        state.isLoading = false;
        state.linkError = action.payload as AxiosError;
      })
      .addCase(createLink.pending, () => {})
      .addCase(createLink.fulfilled, (state, action) => {
        state.links = action.payload;
      })
      .addCase(createLink.rejected, (state, action) => {
        state.linkError = action.payload as AxiosError;
      })
      .addCase(createLinks.pending, () => {})
      .addCase(createLinks.fulfilled, () => {})
      .addCase(createLinks.rejected, (state, action) => {
        state.linkError = action.payload as AxiosError;
      })
      .addCase(getLinkByAlias.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLinkByAlias.fulfilled, (state, action) => {
        state.isLoading = false;
        state.link = action.payload;
      })
      .addCase(getLinkByAlias.rejected, (state, action) => {
        state.isLoading = false;
        state.linkError = action.payload as AxiosError;
      });
  }),
});

export const linksReducer = linksSlice.reducer;
export default linksSlice.actions;
