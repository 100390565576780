const themeSettings = () => ({
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#fff1f1',
    },
    background: {
      default: '#e3ebed',
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: '#e3ebed',
        },
      },
    },
  },
  typography: {
    h1: {
      fontSize: 55.41,
    },
    h2: {
      fontSize: 45.04,
    },
    h3: {
      fontSize: 36.62,
    },
    h4: {
      fontSize: 29.77,
    },
    h5: {
      fontSize: 24.21,
    },
    h6: {
      fontSize: 19.68,
    },
  },
});

export default themeSettings;
