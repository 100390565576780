import { createAsyncThunk } from '@reduxjs/toolkit';
import { User } from 'src/types/user';
import api from 'src/api/user';

export const getUser = createAsyncThunk(
  '@user/getUser',
  async (userId: string, thunkAPI) => {
    try {
      return await api.getUserById(userId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.data);
    }
  },
);

export const update = createAsyncThunk(
  '@user/update',
  async (args: { userId: string, user: Partial<User> }, thunkAPI) => {
    const { userId, user } = args;

    try {
      return await api.update(userId, user) as unknown as User;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.data);
    }
  },
);

export const updatePicture = createAsyncThunk(
  '@user/updatePicture',
  async (args: { userId: string, formData: FormData }, thunkAPI) => {
    const { userId, formData } = args;

    try {
      return await api.updatePicture(userId, formData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.data);
    }
  },
);

export const deletePicture = createAsyncThunk(
  '@user/deletePicture',
  async (userId: string, thunkAPI) => {
    try {
      return await api.deletePicture(userId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
