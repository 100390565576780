import { Scan, TLink } from 'src/types/link';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/api/links';

export const createLinks = createAsyncThunk(
  '@tags/createLinks',
  async (linksArray: Partial<TLink>[], thunkAPI) => {
    try {
      return await api.createLinks(linksArray);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.data);
    }
  },
);
export const createLink = createAsyncThunk(
  '@users/createLink',
  async (link: Partial<TLink>, thunkAPI) => {
    try {
      return await api.createLink(link);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.data);
    }
  },
);
export const deleteLink = createAsyncThunk(
  '@users/deleteLink',
  async (id: string, thunkAPI) => {
    try {
      return await api.deleteLink(id) as unknown as TLink[];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.data);
    }
  },
);

export const updateLink = createAsyncThunk(
  '@users/updateLink',
  async ({ id, data }: { id: string, data: Partial<TLink> }, thunkAPI) => {
    try {
      return await api.updateLink(id, data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.data);
    }
  },
);
export const assignLink = createAsyncThunk(
  '@tags/assignLink',
  async ({ alias, userId }: { alias: string, userId: string }, thunkAPI) => {
    try {
      return await api.assignLink(alias, userId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.data);
    }
  },
);

export const getLinks = createAsyncThunk(
  '@links/getLinks',
  async (_, thunkAPI) => {
    try {
      return await api.getLinks();
    } catch (error) {
      return thunkAPI.rejectWithValue(error.data);
    }
  },
);

export const getLinkByAlias = createAsyncThunk(
  '@links/getLinkByAlias',
  async (alias: string, thunkAPI) => {
    try {
      return await api.getLinkByAlias(alias);
    } catch (error) {
      return thunkAPI.rejectWithValue({
        data: error.data,
        status: error.status,
      });
    }
  },
);

export const updateLinkScansByAlias = createAsyncThunk(
  '@links/updateLinkScansByAlias',
  async ({ alias, data }: { alias: string, data: Partial<Scan> }, thunkAPI) => {
    try {
      return await api.updateLinkScansByAlias(alias, data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.data);
    }
  },
);
