import React, { useState } from 'react';
import {
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import RedirectLinkForm from 'src/pages/Account/components/RedirectLinkForm';
import ApplicationLinkForm from 'src/pages/Account/components/ApplicationLinkForm';
import { LinkType } from 'src/constants/enums';

interface AddLinkFormProps {
  userId: string
}

function AddLinkForm({ userId }: AddLinkFormProps) {
  const [linkType, setLinkType] = useState<LinkType>(LinkType.REDIRECT);
  const { t } = useTranslation();
  const types = [
    { type: LinkType.REDIRECT, title: t('Redirect') },
    { type: LinkType.APPLICATION, title: t('Application') },
  ];

  const renderForm = (type: LinkType) => {
    switch (type) {
      case LinkType.REDIRECT:
        return <RedirectLinkForm userId={userId} />;
      case LinkType.APPLICATION:
        return <ApplicationLinkForm userId={userId} />;

      default:
        return null;
    }
  };

  return (
    <div className="grid gap-4">
      <ToggleButtonGroup
        value={linkType}
        exclusive
        onChange={(_, value: LinkType) => setLinkType(value)}
        fullWidth
      >
        {types.map(({ type, title }) => (
          <ToggleButton key={type} value={type}>
            {title}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      {renderForm(linkType)}
    </div>
  );
}

export default AddLinkForm;
