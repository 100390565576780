import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src/hooks';
import UAParser from 'ua-parser-js';
import { Scan, TLink } from 'src/types/link';
import {
  Box,
  Card,
  CardContent,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import { useTranslation } from 'react-i18next';
import { formatNumber, timeAgo } from 'src/utils';
import useActions from 'src/hooks/useActions';

function LinkEditPage() {
  const { links } = useAppSelector((state) => state.links);
  const { id } = useParams();
  const { getLinks } = useActions();
  const [link, setLink] = useState<TLink | null>(null);
  const [clicks, setClicks] = useState(0);
  const [scans, setScans] = useState<Scan[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const foundLink = links?.find((linkItem: { id: string | undefined; }) => linkItem.id === id);

    if (foundLink) {
      setLink(foundLink);

      if (foundLink.clicks) {
        setClicks(foundLink.clicks);
      }
      if (foundLink.scans) {
        const sortedScans = [...foundLink.scans];

        sortedScans.sort((
          a: any,
          b: any,
        ) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        setScans(sortedScans);
      }
    }
  }, [links]);

  useEffect(() => {
    getLinks();
  }, []);

  return (
    <Container>
      <div className="grid gap-4 p-4">
        <Card>
          <CardContent className="grid gap-2">
            <Typography variant="h6" color={(theme) => theme.palette.grey['700']}>Всього переглядів</Typography>
            <Box className="flex gap-2">
              <QueryStatsOutlinedIcon fontSize="large" />
              <Typography variant="h3" fontWeight="bold" fontFamily="sans-serif">{formatNumber(clicks)}</Typography>
            </Box>
            <Typography color={(theme) => theme.palette.grey['500']}>{link?.alias}</Typography>
          </CardContent>
        </Card>

        <TableContainer component={Paper}>
          <Table aria-label={t('Scans table')} size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('When')}</TableCell>
                <TableCell align="right">{t('Place')}</TableCell>
                <TableCell align="right">{t('Platform')}</TableCell>
                <TableCell align="right">Referrer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scans.map(({
                geolocation, createdAt, userAgent, referrer,
              }) => (
                <TableRow
                  key={`_key${createdAt}`}
                  className="whitespace-nowrap"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{timeAgo(createdAt)}</TableCell>
                  <TableCell align="right">{geolocation?.city}</TableCell>
                  <TableCell align="right">{new UAParser().setUA(userAgent).getResult().os.name}</TableCell>
                  <TableCell align="right">{referrer}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  );
}

export default LinkEditPage;
