import {
  ListItem, SvgIcon, Switch, TextField,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { Dispatch, SetStateAction } from 'react';
import { ReactComponent as FacebookIcon } from 'src/assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from 'src/assets/icons/instagram.svg';
import { ReactComponent as LocationIcon } from 'src/assets/icons/location.svg';
import { ReactComponent as TelegramIcon } from 'src/assets/icons/telegram.svg';
import { ReactComponent as TikTokIcon } from 'src/assets/icons/tiktok.svg';
import { ReactComponent as ViberIcon } from 'src/assets/icons/viber.svg';
import { ReactComponent as WhatsAppIcon } from 'src/assets/icons/whatsapp.svg';
import { ReactComponent as YouTubeIcon } from 'src/assets/icons/youtube.svg';
import { ReactComponent as SpotifyIcon } from 'src/assets/icons/spotify.svg';
import { SocialLink, User } from 'src/types/user';

function SocialLinksForm({ form, setForm }: {
  form: User,
  setForm: Dispatch<SetStateAction<User>>
}) {
  const { phone } = form;

  const socialLinksSettings = [
    {
      type: 'telegram',
      title: 'Telegram',
      icon: <SvgIcon fontSize="large"><TelegramIcon /></SvgIcon>,
      autocomplete: 'off',
      placeholder: '@yourusername',
    },
    {
      type: 'instagram',
      title: 'Instagram',
      icon: <SvgIcon fontSize="large"><InstagramIcon /></SvgIcon>,
      autocomplete: 'off',
      placeholder: 'https://instagram.com/yourname',
    },
    {
      type: 'viber',
      title: 'Viber',
      icon: <SvgIcon fontSize="large"><ViberIcon /></SvgIcon>,
      autocomplete: 'off',
      hiddenValue: true,
      value: 'viber://add?number=%1',
    },
    {
      type: 'whatsapp',
      title: 'WhatsApp',
      icon: <SvgIcon fontSize="large"><WhatsAppIcon /></SvgIcon>,
      autocomplete: 'off',
      hiddenValue: true,
      value: 'https://wa.me/%1',
    },
    {
      type: 'tiktok',
      title: 'TikTok',
      icon: <SvgIcon fontSize="large"><TikTokIcon /></SvgIcon>,
      autocomplete: 'off',
      placeholder: 'https://tiktok.com/@your_tiktok_username',
    },
    {
      type: 'facebook',
      title: 'Facebook',
      icon: <SvgIcon fontSize="large"><FacebookIcon /></SvgIcon>,
      autocomplete: 'off',
      placeholder: 'https://facebook.com/your_facebook_username',
    },
    {
      type: 'youtube',
      title: 'YouTube',
      icon: <SvgIcon fontSize="large"><YouTubeIcon /></SvgIcon>,
      autocomplete: 'off',
      placeholder: 'https://youtube.com/channel/youtube_channel',
    },
    {
      type: 'place',
      title: 'Google Place',
      icon: <SvgIcon fontSize="large"><LocationIcon /></SvgIcon>,
      autocomplete: 'off',
      placeholder: 'https://maps.app.goo.gl/your_place_id',
    },
    {
      type: 'spotify',
      title: 'Spotify',
      icon: <SvgIcon fontSize="large"><SpotifyIcon /></SvgIcon>,
      autocomplete: 'off',
      placeholder: 'spotify:user:spotify:playlist:37i9dQZF1DXcBWIGoYBM5M',
    },
  ];

  const handleSocialLinkChange = (
    index: number,
    field: keyof SocialLink,
    value: string | boolean,
  ) => {
    const updatedSocialLinks = [...(form.socialLinks as SocialLink[])];
    const settingsValue = socialLinksSettings
      .find((item) => item.type === updatedSocialLinks[index].type)?.value;
    let modifiedValue;

    if ((updatedSocialLinks[index].type === 'viber' || updatedSocialLinks[index].type === 'whatsapp') && settingsValue && phone.value) {
      updatedSocialLinks[index] = {
        ...updatedSocialLinks[index],
        [field]: value,
        url: settingsValue.replace('%1', phone.value),
      };
    } else {
      updatedSocialLinks[index] = {
        ...updatedSocialLinks[index],
        [field]: modifiedValue || value,
      };
    }

    setForm({
      ...form,
      socialLinks: updatedSocialLinks,
    });
  };

  const renderLinks = () => form.socialLinks.map((socialLink: SocialLink, index: number) => {
    const {
      id, type, active, url,
    } = socialLink;

    const settings = socialLinksSettings.find((setting) => setting.type === type);

    if (!settings) return null;

    const {
      icon,
      title,
      hiddenValue,
      autocomplete,
      placeholder,
    } = settings;

    return (
      <React.Fragment key={id}>
        <ListItem>
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          <ListItemText
            id={`switch-list-label-${socialLink.id}`}
            primary={title}
          />
          <Switch
            edge="end"
            name={`${socialLink.type}_checkbox`}
            checked={socialLink.active}
            onChange={() => handleSocialLinkChange(index, 'active', !socialLink.active)}
            inputProps={{ 'aria-labelledby': `switch-list-label-${socialLink.id}` }}
          />
        </ListItem>

        {(active && !hiddenValue) && (
        <ListItem>
          <TextField
            size="small"
            fullWidth
            required
            variant="outlined"
            value={url}
            name={type}
            onChange={(e) => handleSocialLinkChange(index, 'url', e.target.value)}
            autoComplete={autocomplete}
            placeholder={placeholder}
          />
        </ListItem>
        )}
      </React.Fragment>
    );
  });

  return (
    <>{renderLinks()}</>
  );
}

export default SocialLinksForm;
