const useSessionStorage = () => {
  const setItem = (key: string, value: any) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  };

  const getItem = (key: string) => {
    const userString = sessionStorage.getItem(key);

    if (userString) {
      try {
        return JSON.parse(userString);
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  };

  const removeItem = (key: string) => {
    sessionStorage.removeItem(key);
  };

  return { setItem, getItem, removeItem };
};

export default useSessionStorage;
