import { Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

interface FormSignInProps {
  onSubmit: ({
    email,
    password,
  }: {
    email: string,
    password: string,
  }) => void
}

function FormSignIn({ onSubmit }: FormSignInProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (email.length && password.length) {
      onSubmit({ email, password });
    }
  };

  return (
    <form className="grid gap-2" onSubmit={handleSubmit}>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        type="email"
        value={email}
        label="Email"
        name="email"
        onChange={(e: any) => setEmail(e.target.value)}
        autoComplete="email"
        required
      />
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        value={password}
        name="password"
        label={t('Password')}
        type="password"
        onChange={(e: any) => setPassword(e.target.value)}
        required
      />
      {/* <Button component={Link} to="/"> */}
      {/*    Забули пароль? */}
      {/* </Button> */}

      <Button fullWidth variant="contained" type="submit">{t('Sign In')}</Button>
    </form>
  );
}

export default FormSignIn;
