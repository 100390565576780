import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  SvgIcon,
  Typography,
} from '@mui/material';
import UserCard from 'src/pages/Link/components/UserCard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QrCodeView from 'src/components/QrCodeView';
import { UserBio } from 'src/types/user';

function Hero() {
  const { t } = useTranslation();

  const demoUser: UserBio = {
    link: {
      value: 'https://cattlet.com',
      label: t('Your Website'),
    },
    cardNumber: '9999999999999999',
    phone: '380999999999',
    name: 'John Doe',
    socialLinks: [
      {
        id: '1',
        type: 'place',
        url: '',
        active: true,
      },
      {
        id: '2',
        type: 'viber',
        url: '',
        active: true,
      },
      {
        id: '3',
        type: 'whatsapp',
        url: '',
        active: true,
      },
      {
        id: '4',
        type: 'telegram',
        url: 'https://t.me/ctltg',
        active: true,
      },
      {
        id: '5',
        type: 'instagram',
        url: 'https://instagram.com/cattle_tag_qr_codes',
        active: true,
      },
      {
        id: '6',
        type: 'tiktok',
        url: '',
        active: true,
      },
      {
        id: '7',
        type: 'facebook',
        url: '',
        active: true,
      },
      {
        id: '8',
        type: 'youtube',
        url: '',
        active: true,
      },
    ],
    notifications: true,
    description: 'Some description!',
  };

  return (
    <Container>
      <Box className="bg-black px-2 py-8 sm:p-16 rounded-xl flex flex-col items-center lg:grid gap-16 lg:grid-cols-12">
        <div className="lg:mr-auto place-self-center lg:col-span-7">
          <Typography variant="h1" fontWeight="bold" color="secondary" fontFamily="Black Street, arial" letterSpacing="3px">
            {t('Dynamic')}
            <span className="italic">
              {' '}
              {t('QR codes')}
              {' '}
            </span>
            {t('and')}
            {' '}
            <span className="italic">
              {' '}
              {t('links')}
              {' '}
            </span>
          </Typography>
          <Typography variant="h6" color="secondary">
            {t('Promote your brand company with a'
            + ' dynamic QR code on'
            + ' packaging')}
          </Typography>
          <Link to="/auth" className="block mt-4">
            <Button
              color="secondary"
              endIcon={<SvgIcon><svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></SvgIcon>}
              variant="contained"
              size="large"
            >
              {t('Try for free')}
            </Button>
          </Link>
        </div>
        <div className="lg:mt-0 lg:col-span-5 flex justify-center items-center w-full relative">
          <QrCodeView className="qr-code-3d hidden lg:block -left-16 !absolute" url="https://cattlet.com/xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx" />
          <Box className="relative" sx={{ maxWidth: '375px' }}>
            <UserCard userBio={demoUser} />
          </Box>
        </div>
      </Box>
    </Container>
  );
}

export default Hero;
