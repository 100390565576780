import { Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

interface FormSignUpProps {
  onSubmit: ({
    email,
    password,
    passwordConfirm,
  }: {
    email: string,
    password: string,
    passwordConfirm: string,
  }) => void
}

function FormSignUp({ onSubmit }: FormSignUpProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const { t } = useTranslation();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (email.length && password.length && passwordConfirm === password) {
      onSubmit({
        email, password, passwordConfirm,
      });
    }
  };

  return (
    <form className="grid gap-2 w-full" onSubmit={handleSubmit}>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        type="email"
        value={email}
        label="Email"
        name="email"
        onChange={(e: any) => setEmail(e.target.value)}
        autoComplete="email"
        required
      />
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        value={password}
        name="password"
        label={t('Password')}
        type="password"
        onChange={(e: any) => setPassword(e.target.value)}
        required
      />
      <TextField
        error={passwordConfirm !== password}
        fullWidth
        variant="outlined"
        size="small"
        value={passwordConfirm}
        name="password-confirm"
        label={t('Confirm password')}
        type="password"
        onChange={(e: any) => setPasswordConfirm(e.target.value)}
        required
      />

      <Button fullWidth variant="contained" type="submit">{t('Sign Up')}</Button>
    </form>
  );
}

export default FormSignUp;
