import { Navigate, useLocation } from 'react-router-dom';
import React, { ReactNode } from 'react';
import { useAppSelector } from 'src/hooks';

interface LoggedInRouteProps {
  children: ReactNode | ReactNode[],
}

function LoggedInRoute({ children }: LoggedInRouteProps) {
  const { pathname } = useLocation();
  const { user } = useAppSelector((state) => state.auth);

  const renderContent = () => (user && pathname === '/' ? <Navigate to="/account" replace /> : children);

  return <>{renderContent()}</>;
}

export default LoggedInRoute;
