import { Navigate } from 'react-router-dom';
import React, { ReactNode } from 'react';
import { useAppSelector } from 'src/hooks';

interface ProtectedRouteProps {
  children: ReactNode | ReactNode[];
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { user } = useAppSelector((state) => state.auth);

  return (
    <>
      {!user && <Navigate to="/auth" replace />}
      {children}
    </>
  );
}

export default ProtectedRoute;
