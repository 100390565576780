import {
  FormControl, IconButton, InputAdornment, OutlinedInput, useTheme,
} from '@mui/material';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import React from 'react';
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

interface CopyTextFieldProps {
  name: string,
  prefixValue: string,
  value: string,
  copyText: string
}

function CopyTextField({
  name, prefixValue, value, copyText,
}: CopyTextFieldProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleClickCopyLink = (text: string) => {
    copy(text);
    toast.success(t('Copied: %t').replace('%t', text));
  };

  return (
    <FormControl
      size="small"
      sx={{
        '& .MuiInputBase-root.Mui-disabled': {
          '& > fieldset': {
            borderColor: theme.palette.secondary.main,
          },
        },
        '& .MuiInputAdornment-root': {
          marginRight: 0,
        },
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: theme.palette.secondary.main,
          color: theme.palette.secondary.main,
        },
      }}
    >
      <OutlinedInput
        name={name}
        fullWidth
        disabled
        value={value}
        startAdornment={(
          <InputAdornment
            sx={{
              '& .MuiTypography-root': {
                color: theme.palette.secondary.main,
              },
            }}
            position="start"
          >
            {prefixValue}
          </InputAdornment>
      )}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="Copy link"
              edge="end"
              onClick={() => handleClickCopyLink(copyText)}
            >
              <ContentCopyOutlinedIcon color="secondary" />
            </IconButton>
          </InputAdornment>
      )}
      />
    </FormControl>
  );
}

export default CopyTextField;
