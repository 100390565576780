import React from 'react';
import { TLink } from 'src/types/link';
import UserLinkItem from 'src/components/LinksList/UserLinkItem';

interface LinksListProps {
  links: TLink[]
}

function LinksList({ links }: LinksListProps) {
  return (
    <ul className="grid gap-2 mt-4">
      {links.map(((link) => <UserLinkItem key={link.id} link={link} />))}
    </ul>
  );
}

export default LinksList;
