import {
  defaultCountries,
  parseCountry,
  usePhoneInput,
  CountryIso2,
  FlagImage,
} from 'react-international-phone';
import 'react-international-phone/style.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BaseTextFieldProps, InputAdornment, MenuItem, Select, TextField, Typography,
} from '@mui/material';

export interface MUIPhoneProps extends BaseTextFieldProps {
  value: string;
  onChange: (phone: string) => void;
}

function MuiPhone({ value, onChange }: MUIPhoneProps) {
  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ['ua'].includes(iso2);
  });
  const { t } = useTranslation();

  const {
    inputValue, handlePhoneValueChange, inputRef, country, setCountry,
  } = usePhoneInput({
    defaultCountry: 'ua',
    value,
    forceDialCode: true,
    disableDialCodePrefill: true,
    countries: defaultCountries,
    onChange: (data) => {
      onChange(data.phone);
    },
  });

  return (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      color="primary"
      placeholder={t('Phone Number')}
      value={inputValue}
      onChange={handlePhoneValueChange}
      type="tel"
      inputRef={inputRef}
      autoComplete="tel"
      inputMode="numeric"
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginRight: '2px', marginLeft: '-8px' }}
          >
            <Select
              MenuProps={{
                style: {
                  left: '-34px',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              sx={{
                width: 'max-content',
                // Remove default outline (display only on focus)
                fieldset: {
                  display: 'none',
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: 'block',
                  },
                },
                // Update default spacing
                '.MuiSelect-select': {
                  padding: '8px',
                  paddingRight: '24px !important',
                },
                svg: {
                  right: 0,
                },
              }}
              value={country.iso2}
              onChange={(e) => setCountry(e.target.value as CountryIso2)}
              renderValue={(flagValue) => (
                <FlagImage iso2={flagValue} style={{ display: 'flex' }} />
              )}
            >
              {countries.map((c) => {
                const { iso2, name, dialCode } = parseCountry(c);

                return (
                  <MenuItem key={iso2} value={iso2}>
                    <FlagImage
                      iso2={iso2}
                      style={{ marginRight: '8px' }}
                    />
                    <Typography marginRight="8px">{name}</Typography>
                    <Typography color="gray">
                      +
                      {dialCode}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default MuiPhone;
